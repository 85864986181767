/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@design-system/components/Button';
import { AskMoreProps, QuestionAnswerProps } from '@source/pages/SellCar/components/AskFaq/AskFaqProps';
import { TitleValueProps } from '@source/types';
import ASK_FAQ_CONFIGS from '@source/pages/SellCar/components/AskFaq/constants';
import { TitleTypography } from '@design-system/components';
import StyledContainer from '@design-system/styled/Container';

const { Panel } = Collapse;

const StyledContentLeft = styled.div<{ buttonCount: number; isMY: boolean }>`
  text-align: center;
  margin-bottom: 24px;

  ${(props) =>
    !props.isMY &&
    css`
      @media screen and (min-width: 1280px) {
        text-align: left;

        .section-divider {
          display: none;
        }
      }
    `}

  .ask-title {
    ${(props) => props.theme.typo.familyGoogle.bold};
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 32px;
    }

    br {
      display: none;
      @media screen and (min-width: 1280px) {
        display: block;
      }
    }
  }

  .ask-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
  }

  .ask-btn-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 40px;
    justify-content: center;

    @media screen and (min-width: 1280px) {
      margin-top: 20px;
      justify-content: left;
    }

    .ask-btn {
      ${(props) => props.theme.typo.familyGoogle.semiBold};
      text-transform: capitalize;
      margin: 0 8px 8px 0;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (min-width: 576px) {
        margin-right: 6;
      }

      @media screen and (min-width: 1280px) {
        margin-right: 8;
      }

      &.ask-tab-btn {
        height: 40px;
        margin: 0 4px 8px 0;
        padding: 5px 6px;
        font-size: 14px;
        ${(props) => props.theme.typo.familyGoogle.semiBold};
        display: flex;
        flex: 1 1 auto;

        @media screen and (min-width: 375px) {
          margin-right: 6px;
          padding: 5px 8px;
        }

        @media screen and (min-width: 576px) {
          margin-right: 8px;
        }

        @media screen and (min-width: 1280px) {
          width: auto;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const StyledContentRight = styled.div`
  .list_decimal {
    list-style: decimal;
  }
`;

const StyledWrapper = styled.div<{ isMY: boolean }>`
  color: ${(props) => props.theme.color.onSurface};
  ${(props) => props.theme.typo.familyGoogle.regular};
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  ${(props) =>
    props.isMY &&
    css`
      .container-content {
        @media screen and (min-width: 992px) {
          max-width: 800px;
          margin: 0 auto;
        }
      }
    `}

  .ant-collapse {
    background-color: white;
    border-radius: 5px;
    border: none;

    .ant-collapse-item {
      border: none;
      margin-bottom: 15px;
      border: ${(props) => `1px solid ${props.theme.color.surface}`};
      box-shadow: 2px 2px 8px rgba(3, 3, 3, 0.05);

      .ant-collapse-header {
        padding: 21px 56px 21px 21px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        opacity: 1;

        .ant-collapse-header-text {
          ${(props) => props.theme.typo.familyGoogle.semiBold};
        }
      }

      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          padding: 4px 21px 21px 21px;
        }
      }
    }
  }
`;

const AskFaq = ({ countryCode }: { countryCode: string }) => {
  const askFaqs = ASK_FAQ_CONFIGS?.[countryCode] || {};
  const { t, i18n } = useTranslation();
  const { askMore, showSection, faqTitle, faqDesc, sections = [], faqs: faqList } = askFaqs;

  const [faqContents, setFaqContents] = useState([] as QuestionAnswerProps[]);
  const [selectedSection, setSelectedSection] = useState(sections?.[0]?.value);
  const [activeKey, setActiveKey] = useState([] as any);

  const currentLang = countryCode === 'sg' && i18n.language === 'ms-SG' ? 'ms' : i18n.language;
  const isMY = countryCode === 'my';

  useEffect(() => {
    setFaqContents(faqList?.general || []);
  }, [faqList]);

  const onSectionSelect = (sectionName: string | number) => {
    setSelectedSection(sectionName);
    setFaqContents(faqList?.[sectionName]);
    setActiveKey([]);
  };

  const renderButtons = (items: TitleValueProps[]) =>
    items.map((item: TitleValueProps, index: number) => {
      const { title, value } = item;
      return (
        <Button
          type="primary"
          block
          className="ask-btn ask-tab-btn"
          onClick={() => onSectionSelect(value)}
          key={index}
          variant={selectedSection !== value ? 'outline' : 'contained'}
        >
          {t(title)}
        </Button>
      );
    });

  const renderAskMore = (items: AskMoreProps[]) =>
    items?.map((item: AskMoreProps, index: number) => {
      const { title, link, buttonVariant } = item;
      return (
        <Button type="primary" block className="ask-btn ask-more-btn" key={index} variant={buttonVariant}>
          <a href={link} target="_blank" rel="noreferrer">
            {t(title)}
          </a>
        </Button>
      );
    });

  return (
    <StyledWrapper id="faq" isMY={isMY}>
      <StyledContainer>
        <div className="container-content">
          <Row gutter={16}>
            <Col xs={24} lg={24} xl={isMY ? 24 : 8}>
              <StyledContentLeft buttonCount={sections?.length || 0} isMY={isMY}>
                <TitleTypography level={2} className="ask-title">
                  <Trans
                    defaults={faqTitle}
                    // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                    components={{ br: <br /> }}
                  />
                </TitleTypography>
                <div className="section-divider" />
                <div className="ask-desc">{t(faqDesc || '')}</div>
                {sections && showSection && <div className="ask-btn-wrapper">{renderButtons(sections)}</div>}
                {askMore && <div className="ask-btn-wrapper">{renderAskMore(askMore)}</div>}
              </StyledContentLeft>
            </Col>
            <Col xs={24} lg={24} xl={isMY ? 24 : 16}>
              <StyledContentRight>
                {faqContents?.map((faq, index) => (
                  <Collapse
                    key={index}
                    bordered={false}
                    expandIconPosition="end"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                  >
                    <Panel header={t(faq?.question)} key={faq?.id}>
                      <Trans
                        defaults={faq?.answer}
                        components={{
                          div: <div />,
                          ul: <ul />,
                          li: <li />,
                          u: <u />,
                          a: <a />,
                          p: <p />,
                          q: <q />,
                          b: <b />,
                        }}
                        values={{ lang: currentLang }}
                      />
                    </Panel>
                  </Collapse>
                ))}
              </StyledContentRight>
            </Col>
          </Row>
        </div>
      </StyledContainer>
    </StyledWrapper>
  );
};
export default AskFaq;
