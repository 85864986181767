/* eslint-disable import/prefer-default-export */
const renderQuestion = (type: string, region: string, questionCount: number) => {
  const questionsList = [];
  for (let i = 1; i <= questionCount; i += 1) {
    // eslint-disable-next-line no-unused-expressions
    questionsList.push({
      id: `faqs.${type}.question${i}`,
      question: `sellCar.faq.${region}Selling${type}.question${i}.question`,
      answer: `sellCar.faq.${region}Selling${type}.question${i}.answer`,
    });
  }

  return questionsList;
};

const SELL_FAQS_CONTENT_MY = {
  faqTitle: 'sellCar.faq.askMyTukar',
  faqDesc: 'sellCar.faq.askMytukarDesc',
  showSection: true,
  sections: [
    {
      title: 'sellCar.faq.general',
      value: 'general',
    },
    {
      title: 'sellCar.faq.inspectYourCar',
      value: 'inspecting-your-car',
    },
    {
      title: 'sellCar.faq.othersScenarios',
      value: 'others-scenarios',
    },
  ],
  faqs: {
    general: renderQuestion('General', 'my', 5),
    'inspecting-your-car': renderQuestion('Inspecting', 'my', 11),
    'others-scenarios': renderQuestion('Others', 'my', 5),
  },
};

const SELL_FAQS_CONTENT_SG = {
  faqTitle: 'sellCar.faq.askCarro',
  faqDesc: 'sellCar.faq.askCarroDesc',
  askMore: [
    // {
    //   title: 'sellCar.faq.askAQuestion',
    //   value: 'askAQuestion',
    //   link: 'https://ask.carro.sg/',
    //   buttonVariant: 'contained',
    // },
    // {
    //   title: 'sellCar.faq.readMore',
    //   value: 'askAQuestion',
    //   link: 'https://ask.carro.sg/',
    //   buttonVariant: 'outline',
    // },
  ],
  sections: [
    {
      title: 'sellCar.faq.general',
      value: 'general',
    },
  ],
  faqs: {
    general: renderQuestion('General', 'sg', 12),
  },
};

const SELL_FAQS_CONTENT_ID = {
  faqTitle: 'sellCar.faq.askCarro',
  faqDesc: 'sellCar.faq.askCarroDesc',
  sections: [
    {
      title: 'sellCar.faq.general',
      value: 'general',
    },
  ],
  faqs: {
    general: renderQuestion('General', 'id', 12),
  },
};

const SELL_FAQS_CONTENT_TH = {
  faqTitle: 'sellCar.faq.askCarro',
  faqDesc: 'sellCar.faq.askCarroDesc',
  askMore: [
    {
      title: 'sellCar.faq.askAQuestion',
      value: 'askAQuestion',
      link: 'https://th.carro.co/faq#ask',
      buttonVariant: 'contained',
    },
    {
      title: 'sellCar.faq.readMore',
      value: 'askAQuestion',
      link: 'https://th.carro.co/faq',
      buttonVariant: 'outline',
    },
  ],
  sections: [
    {
      title: 'sellCar.faq.general',
      value: 'general',
    },
  ],
  faqs: {
    general: renderQuestion('General', 'th', 12),
  },
};

const SELL_FAQS_CONTENT_HK = {
  faqTitle: 'sellCar.faq.askCarroHK',
  faqDesc: 'sellCar.faq.askCarroHKDesc',
  askMore: [
    // {
    //   title: 'sellCar.faq.askAQuestion',
    //   value: 'askAQuestion',
    //   link: 'https://ask.carro.sg/',
    //   buttonVariant: 'contained',
    // },
    // {
    //   title: 'sellCar.faq.readMore',
    //   value: 'askAQuestion',
    //   link: 'https://ask.carro.sg/',
    //   buttonVariant: 'outline',
    // },
  ],
  sections: [
    {
      title: 'sellCar.faq.general',
      value: 'general',
    },
  ],
  faqs: {
    general: renderQuestion('General', 'hk', 7),
  },
};

const ASK_FAQ_CONFIGS: Record<string, any> = {
  sg: { ...SELL_FAQS_CONTENT_SG },
  id: { ...SELL_FAQS_CONTENT_ID },
  th: { ...SELL_FAQS_CONTENT_TH },
  my: { ...SELL_FAQS_CONTENT_MY },
  hk: { ...SELL_FAQS_CONTENT_HK },
};

export default ASK_FAQ_CONFIGS;
